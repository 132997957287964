import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatInput} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface ValueDialogInterface {
  title: string;
  label: string;
  color: string;
}

@Component({
  selector: 'app-label-dialog',
  templateUrl: './label-dialog.component.html',
  styleUrls: ['./label-dialog.component.scss']
})
export class LabelDialogComponent implements OnInit {
  // @ts-ignore
  addForm: FormGroup;

  constructor(public _dialogRef: MatDialogRef<ValueDialogInterface>,
              @Inject(MAT_DIALOG_DATA) public data: ValueDialogInterface,
              private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.addForm = this._formBuilder.group({
      label: ['', Validators.required],
    });
  }

  close() {
    this._dialogRef.close();
  }

}
