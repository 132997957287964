import {Component, Inject, OnInit} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface CropInterface {
  base64: string;
}

@Component({
  selector: 'app-badge-crop-dialog',
  templateUrl: './badge-crop-dialog.component.html',
  styleUrls: ['./badge-crop-dialog.component.scss']
})
export class BadgeCropDialogComponent implements OnInit {
  constructor(public _dialogRef: MatDialogRef<CropInterface>,
               @Inject(MAT_DIALOG_DATA) public data: CropInterface) {}

  croppedImage: any = '';
  image: string;

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.data.base64 = this.croppedImage;
  }
  ngOnInit() {
    this.image = this.data.base64;
  }

  close() {
    this._dialogRef.close();
  }
}
