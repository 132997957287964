import { Injectable } from '@angular/core';
import { BadgeInterface } from '../../shared/models/badge.model';
import { Observable } from 'rxjs';
import { ApiService } from '../../shared/services/api.service';
import {environment} from '../../../environments/environment';
import {ReturnInterface} from '../../shared/models/return.interface';
import {AccessInterface} from '../../shared/models/access.model';


@Injectable({
  providedIn: 'root'
})
export class BadgeService extends ApiService {

  public getBadge(badgeId: string): Observable<ReturnInterface<BadgeInterface>> {
    return this.get<ReturnInterface<BadgeInterface>>('badge/' + badgeId);
  }
  public getBadges(): Observable<ReturnInterface<BadgeInterface[]>> {
    return this.get<ReturnInterface<BadgeInterface[]>>('badge');
  }
  public addBadge(badge: BadgeInterface): Observable<ReturnInterface<BadgeInterface>> {
    if (badge) {
      return this.post<ReturnInterface<BadgeInterface>>('badge', badge);
    }
  }
  public addPicture(badge: BadgeInterface, file: any): Observable<ReturnInterface<BadgeInterface>> {
    if (file) {
      const fd = new FormData();
      fd.append('picture', file, badge._id + '.' + file.name.split('.').pop());

      return this.post<ReturnInterface<BadgeInterface>>('badge/' + badge._id, fd);
    }
  }
  public async deleteBadge(badge: BadgeInterface): Promise<ReturnInterface> {
    return new Promise<ReturnInterface>(resolve => {
      this.delete('badge/' + badge._id)
        .subscribe(res => {
          if (res) {
            resolve(res);
          }
        });
    });
  }
  public async updateBadge(badge: BadgeInterface, body: any): Promise<ReturnInterface<BadgeInterface>> {
    return new Promise<ReturnInterface<BadgeInterface>>(resolve => {
      this.put<ReturnInterface<BadgeInterface>>('badge/' + badge._id, body)
        .subscribe(res => {
          if (res) {
            resolve(res);
          }
        });
    });
  }
  public getPicture(badgeId: string): Observable<Blob> {
    return this._http.get(`${environment.api.url}badge/${badgeId}/picture`, { responseType: 'blob' });
  }
}
