import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { BadgeService } from '../../services/badge.service';
import {
  MatCheckboxChange,
  MatDialog,
  MatPaginator,
  MatSelect,
  MatSnackBar,
  MatSort,
  MatTableDataSource
} from '@angular/material';
import { BadgeInterface } from '../../../shared/models/badge.model';
import { SelectionModel } from '@angular/cdk/collections';
import { BadgeAddDialogComponent } from '../dialog/badge-add-dialog/badge-add-dialog.component';
import {Router} from '@angular/router';
import {AccessInterface} from '../../../shared/models/access.model';
import {FunctionsService} from '../../../shared/services/functions.service';
import {AccessService} from '../../../shared/services/access.service';
import {FunctionsInterface} from '../../../shared/models/functions.model';
import {combineLatest, Observable} from 'rxjs';
import { saveAs } from 'file-saver';
import {ReturnInterface} from '../../../shared/models/return.interface';
import {switchMap} from 'rxjs/operators';
import {$e} from 'codelyzer/angular/styles/chars';

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  // @ts-ignore
  @ViewChild('filterInput') filterInput: ElementRef;
  // @ts-ignore
  @ViewChild('accessSelected') accessSelected: MatSelect;
  // @ts-ignore
  @ViewChild('functionsSelected') functionsSelected: MatSelect;
  // @ts-ignore
  @ViewChild('printSelected') printSelected: MatSelect;

  selection = new SelectionModel<BadgeInterface>(true, [], false);
  isSelected = false;
  displayedColumns: string[] = [
    'select',
    'firstname',
    'lastname',
    'access',
    'functions',
    'validation',
    'restauration',
    'state',
    'actions'
  ];

  accesses: ReturnInterface<AccessInterface[]>;
  functions: ReturnInterface<FunctionsInterface[]>;
  dataSource: MatTableDataSource<ReturnInterface<BadgeInterface>>;
  pictures: any[] = [];

  constructor(private _badgeService: BadgeService,
              private _accessService: AccessService,
              private _functionsService: FunctionsService,
              private _router: Router,
              private _dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private _changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    combineLatest([
      this._accessService.getAccesses(),
      this._functionsService.getFunctions()
    ]).pipe(switchMap(([accesses, functions]) => {
      this.accesses = accesses;
      this.functions = functions;
      return this._badgeService.getBadges();
    })).subscribe((badges) => {
      const newBadges: any[] = [];
      badges.data.forEach(badge => {
        const newBadge: any = badge;
        const findedFunctions = this.functions.data.find((func) => func._id === badge.functionsId);
        const findedAccesses = this.accesses.data.find((access) => access._id === badge.accessId);
        newBadge.functions = findedFunctions ? findedFunctions.label : '';
        newBadge.access = findedAccesses ? findedAccesses.label : '';
        newBadges.push(badge);
      });
      this.dataSource = new MatTableDataSource(newBadges);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this._changeDetectorRefs.detectChanges();
    });
  }
  addBadge() {
    this._dialog.open(BadgeAddDialogComponent);
  }
  validateBadges() {
    if (this.isSelected) {
      this.selection.selected.forEach(badge => {
        this._badgeService.updateBadge(badge, { validity: true })
          .then(ret => {
            this.loadData();
            this.isSelected = false;
            this.notify(ret.message, 2500);
          });
      });
    }
  }
  deleteBadges() {
    if (this.isSelected) {
      this.selection.selected.forEach(badge => {
        this._badgeService.deleteBadge(badge)
          .then(ret => {
            this.isSelected = false;
            this.notify(ret.message, 2500);
          });
        this.loadData();
      });
    }
  }
  printBadges() {
    window.print();
    this.loadData();
    this.selection.clear();
    this.isSelected = false;
    this.pictures = [];
  }
  // table functions
  applyFilter(filterValue: any, reset: boolean = false) {
    if (reset) {
      this.filterInput.nativeElement.value = '';
      this.accessSelected.value = null;
      this.functionsSelected.value = null;
      this.printSelected.value = null;
      this.loadData();
    }

    if (this.printSelected.value === 'true') {
      //this.dataSource.filterPredicate = (data, filter: boolean) => data.state === true;
    } else if (this.printSelected.value === 'false') {
      //this.dataSource.filterPredicate = (data, filter: boolean) => data.state === false;
    }

    this.dataSource.filter = filterValue;

    let filteredData = this.dataSource.filteredData;
    this.dataSource.data = filteredData;

  }
  // selection row function
  selectRow(element: BadgeInterface) {
    this.selection.toggle(element);

    if (this.selection.selected.length > 0) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
  }
  notify(message: string, time: number) {
    this._snackBar.open(message, 'OK', {
      duration: time,
    });
  }
  export(data: any) {
    const date = new Date();
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv' });
    saveAs(blob, date.getFullYear() + '-' + date.getMonth() + '.csv');
  }

  onSelectAll() {
    this.dataSource.data.forEach(item => {
      this.selectRow(item as unknown as BadgeInterface);
    });
  }
}
