import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface ValidateDialogInterface {
  validate: boolean;
}

@Component({
  selector: 'app-validate-dialog',
  templateUrl: './validate-dialog.component.html',
  styleUrls: ['./validate-dialog.component.scss']
})
export class ValidateDialogComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(public _dialogRef: MatDialogRef<ValidateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ValidateDialogInterface) { }

  ngOnInit() {
    this.data = { validate: true };
  }

  close() {
    this.data = { validate: false };
    this._dialogRef.close();
  }
}
