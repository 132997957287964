import {Component, OnInit, ViewChild} from '@angular/core';
import { FunctionsInterface } from '../../models/functions.model';
import {FunctionsService} from '../../services/functions.service';
import {AccessService} from '../../services/access.service';
import {AccessInterface} from '../../models/access.model';
import {ErrorStateMatcher, MatDialog, MatSelect, MatSnackBar} from '@angular/material';
import {ValidateDialogComponent} from '../dialog/validate-dialog/validate-dialog.component';
import {Observable} from 'rxjs';
import {LabelDialogColorComponent} from '../dialog/label-dialog-color/label-dialog-color.component';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {AuthService} from '../../services/auth.service';
import {UserInterface} from '../../models/user.model';
import {LabelDialogComponent} from '../dialog/label-dialog/label-dialog.component';
import {ReturnInterface} from '../../models/return.interface';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  functions$: Observable<ReturnInterface<FunctionsInterface[]>>;
  accesses$: Observable<ReturnInterface<AccessInterface[]>>;
  user$: Observable<ReturnInterface<UserInterface>>;

  // Var for dialog modal
  validation: boolean;
  label: string;

  // @ts-ignore
  @ViewChild('selectedFunctions') selectedFunctions: MatSelect;
  // @ts-ignore
  @ViewChild('selectedAccess') selectedAccess: MatSelect;

  loginFormGroup: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();

  generateUrlGroup: FormGroup;
  generatedUrl: string;

  constructor(private _functionsService: FunctionsService,
              private _accessService: AccessService,
              private _userService: UserService,
              private _authService: AuthService,
              private _dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.user$ = this._userService.getUser(this._authService.getLogin());
    this.accesses$ = this._accessService.getAccesses();
    this.functions$ = this._functionsService.getFunctions();

    this.generateUrlGroup = this._formBuilder.group({
      access: ['', Validators.required],
      functions: ['', Validators.required]
    });

    this.loginFormGroup = this._formBuilder.group({
      login: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      password: [],
      passwordConfirm: [],

    }, {
      validator: this.passwordValidator
    });
  }

  deleteAccess(access: AccessInterface) {
    const dialogRef = this._dialog.open(ValidateDialogComponent, {
      data: { validation: this.validation }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this._accessService.deletesAccess(access)
            .then(ret => {
              this.accesses$ = this._accessService.getAccesses();
              this._snackBar.open(ret.message, 'OK', {
                duration: 2500
              });
            });
        }
      });
  }
  addAccess() {
    const dialogRef = this._dialog.open(LabelDialogColorComponent, {
      data: { title: 'Accès', label: this.label, color: '' }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this._accessService.addAccess({ label: res.label, color: res.color })
            .then(() => {
              this.accesses$ = this._accessService.getAccesses();
            });
          }
      });
  }
  updateAccess(access: AccessInterface) {
    const dialogRef = this._dialog.open(LabelDialogColorComponent, {
      data: { title: 'Accès', label: access.label, color: access.color }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this._accessService.updatesAccess(access, { label: res.label, color: res.color })
            .then(() => {
              this.accesses$ = this._accessService.getAccesses();
            });
        }
      });
  }

  deleteFunctions(functions: FunctionsInterface) {
    const dialogRef = this._dialog.open(ValidateDialogComponent, {
      data: { validation: this.validation }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this._functionsService.deleteFunctions(functions)
            .then(ret => {
              this.functions$ = this._functionsService.getFunctions();
              this._snackBar.open(ret.message, 'OK', {
                duration: 2500
              });
            });
        }
      });
  }
  addFunctions() {
    const dialogRef = this._dialog.open(LabelDialogComponent, {
      data: { title: 'Fonction', label: this.label }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          if (res.label !== '') {
            this._functionsService.addFunctions({ label: res.label })
              .then(() => {
                this.functions$ = this._functionsService.getFunctions();
              });
          }
        }
      });
  }
  updateFunctions(functions: FunctionsInterface) {
    const dialogRef = this._dialog.open(LabelDialogComponent, {
      data: { title: 'Fonction', label: functions.label }
    });
    dialogRef.afterClosed()
      .subscribe(res => {
        if (res) {
          this._functionsService.updatesFunctions(functions, { label: res.label })
            .then(() => {
              this.functions$ = this._functionsService.getFunctions();
            });
        }
      });
  }

  generateUrl() {
    if (this.selectedAccess.value && this.selectedFunctions.value) {
      const url = document.location.origin + '/badge/add/' + this.selectedAccess.value + '/' + this.selectedFunctions.value;
      this.generatedUrl = url;
    }
  }

  saveLogin() {
    if (this.loginFormGroup.valid && this.loginFormGroup.value) {
      this.user$.subscribe(user => {
        this._userService.updateUser(user.data, this.loginFormGroup.value)
          .then(ret => {
            this._snackBar.open(ret.message, 'OK', {
              duration: 2500
            });
          });
      });
    }
  }

  colorChange(access: AccessInterface) {
    this._accessService.updatesAccess(access, { color: access.color })
      .then();
  }

  copy(value: string, title: string) {
    const copy = document.createElement('textarea');
    copy.style.position = 'fixed';
    copy.style.left = '0';
    copy.style.top = '0';
    copy.style.opacity = '0';
    copy.value = value;
    document.body.appendChild(copy);
    copy.focus();
    copy.select();
    document.execCommand('copy');
    document.body.removeChild(copy);

    this._snackBar.open(title + ' copié', 'OK', {
      duration: 2500
    });
  }

  passwordValidator(form: FormGroup) { // here we have the 'passwords' group
    const condition = form.get('password').value !== form.get('passwordConfirm').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }
}
