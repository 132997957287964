import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserInterface } from '../models/user.model';
import {Observable} from 'rxjs';
import {ReturnInterface} from '../models/return.interface';


@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  public getUser(id: string): Observable<ReturnInterface<UserInterface>> {
    return this.get<ReturnInterface<UserInterface>>('user/' + id);
  }
  public async updateUser(user: UserInterface, body: any): Promise<ReturnInterface<UserInterface>> {
    return new Promise<ReturnInterface<UserInterface>>(resolve => {
      this.put<ReturnInterface<UserInterface>>('user/' + user._id, body)
        .subscribe(res => {
          if (res) {
            resolve(res);
          }
        });
    });
  }
}
