import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(public _http: HttpClient) {}

  public get<T>(path: string): Observable<T> {
    return this._http.get<T>(`${environment.api.url}${path}`);
  }

  public put<T>(path: string, body: any): Observable<T> {
    return this._http.put<T>(`${environment.api.url}${path}`, body);
  }

  public post<T>(path: string, body: any): Observable<T> {
    return this._http.post<T>(`${environment.api.url}${path}`, body);
  }

  public delete(path): Observable<any> {
    return this._http.delete(`${environment.api.url}${path}`);
  }
}
