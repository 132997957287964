import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { UserInterface } from '../models/user.model';
import {ReturnInterface} from '../models/return.interface';

@Injectable({
  providedIn: 'root'
})

export class AuthService extends ApiService {

  login(user: any): Observable<ReturnInterface<UserInterface>> {
    return this.post<ReturnInterface<UserInterface>>('login', user);
  }
  loggedIn() {
    // !! for return true or flase if exist
    return !!localStorage.getItem('token');
  }
  getToken() {
    return localStorage.getItem('token');
  }
  getLogin() {
    return localStorage.getItem('login');
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
  }
}
