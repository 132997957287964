import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BadgeService } from '../../services/badge.service';
import { BadgeInterface } from '../../../shared/models/badge.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AccessService} from '../../../shared/services/access.service';
import {FunctionsService} from '../../../shared/services/functions.service';
import {BadgeCropDialogComponent} from '../dialog/badge-crop-dialog/badge-crop-dialog.component';
import {ToolsService} from '../../services/tools.service';
import {AccessInterface} from '../../../shared/models/access.model';
import {FunctionsInterface} from '../../../shared/models/functions.model';
import {Observable} from 'rxjs';
import {ReturnInterface} from '../../../shared/models/return.interface';
import {BadgeWebcamDialogComponent} from '../dialog/badge-webcam-dialog/badge-webcam-dialog.component';

const imageType = [
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'gif',
];

@Component({
  selector: 'app-badge-edit',
  templateUrl: './badge-edit.component.html',
  styleUrls: ['./badge-edit.component.scss']
})
export class BadgeEditComponent implements OnInit {
// @ts-ignore
  @ViewChild('email') email: ElementRef;
  // @ts-ignore
  @ViewChild('firstname') firstname: ElementRef;
  // @ts-ignore
  @ViewChild('lastname') lastname: ElementRef;
  // @ts-ignore
  @ViewChild('fileInput') fileInput: ElementRef;

  badgeId: string;
  public _badge$: Observable<ReturnInterface<BadgeInterface>>;
  public _access$: Observable<ReturnInterface<AccessInterface>>;
  public _accesses$: Observable<ReturnInterface<AccessInterface[]>>;
  public _function$: Observable<ReturnInterface<FunctionsInterface>>;
  public _functions$: Observable<ReturnInterface<FunctionsInterface[]>>;

  date: Date;

  isInit = false;
  isLinear = false;
  isEditable = true;

  inputFormGroup: FormGroup;
  pictureFormGroup: FormGroup;
  otherFormGroup: FormGroup;

  selectedFile = null;
  selectedFileSrc = '../../assets/profil-default.png';

  constructor(private _badgeService: BadgeService,
              private _accessService: AccessService,
              private _functionsService: FunctionsService,
              private _tools: ToolsService,
              private _formBuilder: FormBuilder,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private _dialog: MatDialog) { }

  ngOnInit() {
    this.date = new Date();
    // Charge le badge
    this.badgeId = this._activatedRoute.snapshot.params.badgeId;
    this._badge$ = this._badgeService.getBadge(this.badgeId);

    // Charge les fonctions et accès
    this._functions$ = this._functionsService.getFunctions();
    this._accesses$ = this._accessService.getAccesses();

    this._badge$
      .subscribe(badge => {
        this._access$ = this._accessService.getAccess(badge.data.accessId);
        this._function$ = this._functionsService.getFunction(badge.data.functionsId);
        // Charge la photo
        this._badgeService.getPicture(this.badgeId)
          .subscribe(picture => {
            const reader = new FileReader();
            reader.readAsDataURL(picture);
            reader.onloadend = () => {
              // @ts-ignore
              this.selectedFileSrc = reader.result;
              this.isInit = true;
            };
          });
      });

    // Initialise le formulaire
    this.inputFormGroup = this._formBuilder.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
    });
    this.pictureFormGroup = this._formBuilder.group({
      picture: ['', Validators.required]
    });
    this.otherFormGroup = this._formBuilder.group({
      accessId: ['', Validators.required],
      functionsId: ['', Validators.required],
      restauration: ['', Validators.required],
      validity: ['', Validators.required],
      state: ['', Validators.required],
    });
  }

  public changeAccess(value: string) {
    this._access$ = this._accessService.getAccess(value);
  }
  public changeFunctions(value: string) {
    this._function$ = this._functionsService.getFunction(value);
  }
  public onSelectFile(): void {
    this.fileInput.nativeElement.click();
  }
  public onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.pictureFormGroup.get('picture').setValue(this.selectedFile.name);

    if (this.selectedFile) {
      const type = this.selectedFile.type.split('/')[1];
      if (imageType.indexOf(type) !== -1) {
        const fr = new FileReader();
        fr.onload = (e) => {
          this.selectedFileSrc = fr.result.toString();
          const dialogRef = this._dialog.open(BadgeCropDialogComponent, {
            data: { base64: this.selectedFileSrc },
            width: '500px',
          });
          dialogRef.afterClosed()
            .subscribe(res => {
              if (res) {
                this.selectedFileSrc = res.base64;
                this.selectedFile = this._tools.dataURLtoFile(this.selectedFileSrc, this.selectedFile.name);
              } else {
                this.selectedFile = null;
                this.selectedFileSrc = null;
                this.pictureFormGroup.get('picture').setValue('');
              }
            });
        };
        fr.readAsDataURL(this.selectedFile);
      } else {
        this.pictureFormGroup.get('picture').setValue('');
        this._snackBar.open('format non supporté', 'OK', {
          duration: 5000,
        });
      }
    }
  }
  public onSubmit(): void {
    if (this.inputFormGroup.value && this.otherFormGroup.value) {
      this.inputFormGroup.value.lastname = this.inputFormGroup.value.lastname.toUpperCase();
      this.isEditable = false;
      this._badge$
        .subscribe(badge => {
          const body = Object.assign({}, this.pictureFormGroup.value, this.otherFormGroup.value, this.inputFormGroup.value);
          this._badgeService.updateBadge(badge.data, body)
            .then(ret => {
              if (this.selectedFile) {
                this._badgeService.addPicture(badge.data, this.selectedFile)
                  .subscribe();
              }
              this._snackBar.open(ret.message, 'OK', {
                duration: 5000,
              });

              this._router.navigate(['/badge']);
            });
        });
    }
  }
  public takePicture() {
    const dialogWebcam = this._dialog.open(BadgeWebcamDialogComponent, {
      data: { base64: '' },
      width: '500px',
    });
    dialogWebcam.afterClosed()
      .subscribe(webcamImage => {
        if (webcamImage) {
          const dialogCrop = this._dialog.open(BadgeCropDialogComponent, {
            data: webcamImage,
            width: '500px',
          });
          dialogCrop.afterClosed()
            .subscribe(cropImage => {
              if (cropImage) {
                this.pictureFormGroup.get('picture').setValue('image.png');
                this.selectedFileSrc = cropImage.base64;
                this.selectedFile = this._tools.dataURLtoFile(this.selectedFileSrc, 'image.png');
              } else {
                this.selectedFile = null;
                this.selectedFileSrc = null;
                this.pictureFormGroup.get('picture').setValue('');
              }
            });
        }
      });
  }

}
