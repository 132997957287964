import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { BadgeInterface } from '../../../shared/models/badge.model';
import { AccessService } from '../../../shared/services/access.service';
import { AccessInterface } from '../../../shared/models/access.model';
import { FunctionsInterface } from '../../../shared/models/functions.model';
import { FunctionsService } from '../../../shared/services/functions.service';
import { Router } from '@angular/router';
import {BadgeService} from '../../services/badge.service';
import {ReturnInterface} from '../../../shared/models/return.interface';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-badge-print',
  templateUrl: './badge-print.component.html',
  styleUrls: ['./badge-print.component.scss']
})
export class BadgePrintComponent implements OnInit {
  date: Date;

  constructor(private _accessService: AccessService,
              private _functionsService: FunctionsService,
              private _badgeService: BadgeService,
              private _router: Router) {}

  public _accesses: ReturnInterface<AccessInterface[]>;
  @Input()
  set accesses(accesses: ReturnInterface<AccessInterface[]>) {
    this._accesses = accesses;
  }

  public _functions: ReturnInterface<FunctionsInterface[]>;
  @Input()
  set functions(functions: ReturnInterface<FunctionsInterface[]>) {
    this._functions = functions;
  }

  public _badges: BadgeInterface[];
  @Input()
  set badges(badges: BadgeInterface[]) {
    console.log(badges);
    this._badges = badges;
    this.addPrintBadge();
  }

  public _pictures: any[];
  @Input()
  set pictures(pictures: any[]) {
    this._pictures = pictures;
  }

  addPrintBadge() {
    if (this._badges.length > 0) {
      this._badges.forEach(badge => {
        this._badgeService.updateBadge(badge, { state: true }).then();
      });
    } else {
      this._router.navigate(['/badge']);
    }
  }

  ngOnInit(): void {
    this.date = new Date();
  }

  getPicture(badge: BadgeInterface) {
    return `${environment.api.url}${badge._id}.${badge.picture.split('.')[1]}`;
  }
}
