import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import { AccessService } from '../../../../shared/services/access.service';
import { FunctionsService } from '../../../../shared/services/functions.service';
import { AccessInterface } from '../../../../shared/models/access.model';
import { FunctionsInterface } from '../../../../shared/models/functions.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-badge-add-dialog',
  templateUrl: 'badge-add-dialog.component.html',
  styleUrls: ['./badge-add-dialog.component.scss']
})
export class BadgeAddDialogComponent implements OnInit {

  addBadgeGroup: FormGroup;

  accesses: AccessInterface[];
  functions: FunctionsInterface[];

  constructor(private _dialogRef: MatDialogRef<BadgeAddDialogComponent>,
              private _accessService: AccessService,
              private _functionsService: FunctionsService,
              private _formBuilder: FormBuilder,
              private _router: Router){
  }

  ngOnInit() {
    this._accessService.getAccesses()
      .subscribe(accesses => {
        this.accesses = accesses.data;
      }, error => { console.log(error); });
    this._functionsService.getFunctions()
      .subscribe(functions => {
        this.functions = functions.data;
      }, error => { console.log(error); });

    this.addBadgeGroup = this._formBuilder.group({
      access: ['', Validators.required],
      functions: ['', Validators.required]
    });
  }

  save() {
    if (this.addBadgeGroup.value) {
      this._dialogRef.close();
      const accessId = this.addBadgeGroup.value.access;
      const functionsId = this.addBadgeGroup.value.functions;
      this._router.navigate(['/badge/add/' + accessId + '/' + functionsId]);
    }
  }

  close() {
    this._dialogRef.close();
  }
}


