import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {AccessInterface} from '../models/access.model';
import {FunctionsInterface} from '../models/functions.model';
import {ReturnInterface} from '../models/return.interface';


@Injectable({
  providedIn: 'root'
})
export class FunctionsService extends ApiService {

  public getFunction(id: string): Observable<ReturnInterface<FunctionsInterface>> {
    return this.get<ReturnInterface<FunctionsInterface>>('functions/' + id);
  }
  public getFunctions(): Observable<ReturnInterface<FunctionsInterface[]>> {
    return this.get<ReturnInterface<FunctionsInterface[]>>('functions');
  }
  public async deleteFunctions(functions: FunctionsInterface): Promise<ReturnInterface> {
    return new Promise<ReturnInterface>(resolve => {
      this.delete('functions/' + functions._id)
        .subscribe(res => {
          if (res) {
            resolve(res);
          }
        });
    });
  }
  public async updatesFunctions(functions: FunctionsInterface, body: any): Promise<any> {
    this.put('functions/' + functions._id, body)
      .subscribe();
  }
  public async addFunctions(body: any): Promise<any> {
    await this.post('functions', body)
      .subscribe();
  }
}
