import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BadgeAddComponent } from './badge/components/badge-add/badge-add.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { BadgeListComponent } from './badge/components/badge-list/badge-list.component';
import { AuthGuard } from './auth.guard';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { BadgeEditComponent } from './badge/components/badge-edit/badge-edit.component';
import {ErrorComponent} from './shared/components/error/error.component';
import {BadgeSuccessComponent} from './badge/components/badge-success/badge-success.component';


const routes: Routes = [
  { path: '', redirectTo: '/badge', pathMatch: 'full' },
  { path: 'badge/add/:accessId/:functionsId', component: BadgeAddComponent },
  { path: 'badge/edit/:badgeId', component: BadgeEditComponent, canActivate: [AuthGuard] },
  { path: 'badge', component: BadgeListComponent, canActivate: [AuthGuard] },
  { path: 'badge/success', component: BadgeSuccessComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'login', component: LoginComponent },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
