import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BadgeService } from '../../services/badge.service';
import { BadgeInterface } from '../../../shared/models/badge.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AccessService} from '../../../shared/services/access.service';
import {FunctionsService} from '../../../shared/services/functions.service';
import {BadgeCropDialogComponent} from '../dialog/badge-crop-dialog/badge-crop-dialog.component';
import {ToolsService} from '../../services/tools.service';
import {AuthService} from '../../../shared/services/auth.service';
import {BadgeWebcamDialogComponent} from '../dialog/badge-webcam-dialog/badge-webcam-dialog.component';

const imageType = [
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'gif',
];


@Component({
  selector: 'app-badge-add',
  templateUrl: './badge-add.component.html',
  styleUrls: ['./badge-add.component.scss']
})
export class BadgeAddComponent implements OnInit {
  // @ts-ignore
  @ViewChild('email') email: ElementRef;
  // @ts-ignore
  @ViewChild('firstname') firstname: ElementRef;
  // @ts-ignore
  @ViewChild('lastname') lastname: ElementRef;
  // @ts-ignore
  @ViewChild('fileInput') fileInput: ElementRef;

  date: Date;

  accessId: string;
  access: string;
  accessColor: string;
  functionsId;
  functions: string;

  isInit = false;

  isLinear = false;
  isEditable = true;

  inputFormGroup: FormGroup;
  pictureFormGroup: FormGroup;

  selectedFile = null;
  selectedFileSrc = '../../assets/profil-default.png';

  badge: BadgeInterface;

  constructor(private _badgeService: BadgeService,
              private _accessService: AccessService,
              private _functionsService: FunctionsService,
              private _guardService: AuthService,
              private _tools: ToolsService,
              private _formBuilder: FormBuilder,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private _dialog: MatDialog) { }

  ngOnInit() {
    this.date = new Date();
    this.accessId = this._activatedRoute.snapshot.params.accessId;
    this._accessService.getAccess(this.accessId)
      .subscribe(access => {
        if (access.data) {
          this.access = access.data.label;
          this.accessColor = access.data.color;
        }
      }, error => {
        switch (error.status) {
          case 404:
            this._router.navigate(['/not-found']);
            break;
        }
      });
    this.functionsId = this._activatedRoute.snapshot.params.functionsId;
    this._functionsService.getFunction(this.functionsId)
      .subscribe(functions => {
        if (functions.data) {
          this.functions = functions.data.label;
        } else {
          this._router.navigate(['/not-found']);
        }
      }, error => {
        switch (error.status) {
          case 404:
            this._router.navigate(['/not-found']);
            break;
        }
      });

    this.inputFormGroup = this._formBuilder.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      accessId: [''],
      functionsId: [''],
    });
    this.pictureFormGroup = this._formBuilder.group({
      picture: ['', Validators.required]
    });

    this.isInit = true;
  }

  public onSelectFile(): void {
    this.fileInput.nativeElement.click();
  }
  public onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.pictureFormGroup.get('picture').setValue(this.selectedFile.name);

    if (this.selectedFile) {
      const type = this.selectedFile.type.split('/')[1];
      if (imageType.indexOf(type) !== -1) {
        const fr = new FileReader();
        fr.onload = (e) => {
          this.selectedFileSrc = fr.result.toString();
          const dialogRef = this._dialog.open(BadgeCropDialogComponent, {
            data: { base64: this.selectedFileSrc },
            width: '500px',
          });
          dialogRef.afterClosed()
            .subscribe(res => {
              if (res) {
                this.selectedFileSrc = res.base64;
                this.selectedFile = this._tools.dataURLtoFile(this.selectedFileSrc, this.selectedFile.name);
              } else {
                this.selectedFile = null;
                this.selectedFileSrc = null;
                this.pictureFormGroup.get('picture').setValue('');
              }
            });
        };
        fr.readAsDataURL(this.selectedFile);
      } else {
        this.pictureFormGroup.get('picture').setValue('');
        this._snackBar.open('format non supporté', 'OK', {
          duration: 5000,
        });
      }
    }
  }
  public onSubmit() {
    if (this.inputFormGroup.value) {
      if (this.selectedFile) {
        this.inputFormGroup.value.lastname = this.inputFormGroup.value.lastname.toUpperCase();
        this.isEditable = false;
        this._badgeService.addBadge(this.inputFormGroup.value)
          .subscribe(badge => {
            this._badgeService.addPicture(badge.data, this.selectedFile)
              .subscribe(updatedBadge => {
                if (updatedBadge) {
                  if (this._guardService.loggedIn()) {
                    this._router.navigate(['/badge'])
                      .then(() => {
                        this._snackBar.open(badge.message, 'OK', {
                          duration: 5000,
                        });
                      });
                  } else {
                    this._router.navigate(['/badge/success']);
                  }
                }
              });
          });
      }
    }
  }

  public takePicture() {
    const dialogWebcam = this._dialog.open(BadgeWebcamDialogComponent, {
      data: { base64: '' },
      width: '500px',
    });
    dialogWebcam.afterClosed()
      .subscribe(webcamImage => {
        if (webcamImage) {
          const dialogCrop = this._dialog.open(BadgeCropDialogComponent, {
            data: webcamImage,
            width: '500px',
          });
          dialogCrop.afterClosed()
            .subscribe(cropImage => {
              if (cropImage) {
                this.pictureFormGroup.get('picture').setValue('image.png');
                this.selectedFileSrc = cropImage.base64;
                this.selectedFile = this._tools.dataURLtoFile(this.selectedFileSrc, 'image.png');
              } else {
                this.selectedFile = null;
                this.selectedFileSrc = null;
                this.pictureFormGroup.get('picture').setValue('');
              }
            });
        }
      });
  }
}
