import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../shared/services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _authService: AuthService,
              private _router: Router) {
    this.loginFormGroup = this._formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginFormGroup.value) {
      this._authService.login(this.loginFormGroup.value)
        .subscribe(user => {
          localStorage.setItem('token', user.data.token);
          localStorage.setItem('login', user.data._id);
          this._router.navigate(['/badge']);
        });
    }
  }
}
