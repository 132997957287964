import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BadgeAddDialogComponent } from './badge/components/dialog/badge-add-dialog/badge-add-dialog.component';
import { BadgeListComponent } from './badge/components/badge-list/badge-list.component';
import { BadgeAddComponent } from './badge/components/badge-add/badge-add.component';
import { LoginComponent } from './login/login.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BadgePrintComponent } from './badge/components/badge-print/badge-print.component';
import { AuthGuard } from './auth.guard';
import { AuthService } from './shared/services/auth.service';
import { InterceptorService } from './shared/services/interceptor.service';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { ValidateDialogComponent } from './shared/components/dialog/validate-dialog/validate-dialog.component';
import { LabelDialogColorComponent } from './shared/components/dialog/label-dialog-color/label-dialog-color.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BadgeCropDialogComponent } from './badge/components/dialog/badge-crop-dialog/badge-crop-dialog.component';
import { QRCodeModule } from 'angular2-qrcode';
import { BadgeEditComponent } from './badge/components/badge-edit/badge-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { LabelDialogComponent } from './shared/components/dialog/label-dialog/label-dialog.component';
import {FilterIdPipe} from './filterId.pipe';
import { ErrorComponent } from './shared/components/error/error.component';
import {WebcamModule} from 'ngx-webcam';
import {BadgeWebcamDialogComponent} from './badge/components/dialog/badge-webcam-dialog/badge-webcam-dialog.component';
import { BadgeSuccessComponent } from './badge/components/badge-success/badge-success.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    ImageCropperModule,
    QRCodeModule,
    ColorPickerModule,
    WebcamModule,
  ],
  entryComponents: [
    BadgeAddDialogComponent,
    ValidateDialogComponent,
    LabelDialogColorComponent,
    LabelDialogComponent,
    BadgeCropDialogComponent,
    BadgeWebcamDialogComponent,
  ],
  declarations: [
    AppComponent,
    BadgeAddComponent,
    NotFoundComponent,
    LoginComponent,
    BadgeAddDialogComponent,
    NavComponent,
    BadgeListComponent,
    BadgePrintComponent,
    SettingsComponent,
    ValidateDialogComponent,
    LabelDialogColorComponent,
    LabelDialogComponent,
    BadgeCropDialogComponent,
    BadgeWebcamDialogComponent,
    BadgeEditComponent,
    FilterIdPipe,
    ErrorComponent,
    BadgeSuccessComponent,
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
