import {Component, Inject, OnInit} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Observable, Subject} from 'rxjs';
import {WebcamImage, WebcamInitError} from 'ngx-webcam';

export interface WebcamInterface {
  base64: string;
}

@Component({
  selector: 'app-badge-webcam-dialog',
  templateUrl: './badge-webcam-dialog.component.html',
  styleUrls: ['./badge-webcam-dialog.component.scss']
})
export class BadgeWebcamDialogComponent implements OnInit {
  public width = 450;
  public image: WebcamImage;
  private trigger: Subject<void> = new Subject<void>();
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  constructor(public _dialogRef: MatDialogRef<WebcamInterface>,
              @Inject(MAT_DIALOG_DATA) public data: WebcamInterface) {}

  ngOnInit(): void {
    if (window.innerWidth < 620) {
      this.width = window.innerWidth / 1.5;
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }
  public handleImage(webcamImage: WebcamImage): void {
    this.image = webcamImage;
    this.data.base64 = webcamImage.imageAsDataUrl;
  }
  public init() {
    this.image = null;
  }

  close() {
    this._dialogRef.close();
  }
}
