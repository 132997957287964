import { Injectable, Injector } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { AuthService } from './auth.service';
import {catchError, retry} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private _injector: Injector,
              private _router: Router,
              private _matSnackBar: MatSnackBar) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authService = this._injector.get(AuthService);
    let tokenized = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getToken()}`
      }
    });

    return next.handle(tokenized)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500 || error.status === 0) {
            this._router.navigate(['error']);
          } else if (error.status === 400 || error.status === 404) {
            this._matSnackBar.open(error.error.message, 'OK', { duration: 4000 });
          } else if (error.status === 401) {
            this._router.navigate(['login']);
          }
          return throwError(error);
        })
      );
  }
}
