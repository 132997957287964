import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterId'
})
export class FilterIdPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    return items.filter( element => {
      return element._id === searchText;
    });
  }
}
