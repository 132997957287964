import { Injectable } from '@angular/core';
import { AccessInterface } from '../models/access.model';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {ReturnInterface} from '../models/return.interface';


@Injectable({
  providedIn: 'root'
})
export class AccessService extends ApiService {

  public getAccess(id: string): Observable<ReturnInterface<AccessInterface>> {
    return this.get<ReturnInterface<AccessInterface>>('access/' + id);
  }
  public getAccesses(): Observable<ReturnInterface<AccessInterface[]>> {
    return this.get<ReturnInterface<AccessInterface[]>>('access');
  }
  public async deletesAccess(access: AccessInterface): Promise<ReturnInterface> {
    return new Promise<ReturnInterface>(resolve => {
      this.delete('access/' + access._id)
        .subscribe(res => {
          if (res) {
            resolve(res);
          }
        });
    });
  }
  public async updatesAccess(access: AccessInterface, body: any): Promise<any> {
    await this.put('access/' + access._id, body)
      .subscribe();
  }
  public async addAccess(body: any): Promise<any> {
    await this.post('access', body)
      .subscribe();
  }
}
